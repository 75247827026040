var __jsx = React.createElement;
import React from 'react';
import Image from 'next/image';
import blockBlue from '../assets/images/blocks/half-block-blue-left.png';
import blockYellow from '../assets/images/blocks/half-block-yellow-right.png';
import { Button } from '../components/Button';
import { Head } from '../components/Head';
import { Layout } from '../components/Layout';
import { Heading, Paragraph } from '../components/Typography';

var Custom404 = function Custom404(props) {
  return __jsx(React.Fragment, null, __jsx(Head, {
    title: "Exodia - Page not found",
    description: "Page not found.",
    pageName: "404"
  }), __jsx(Layout, null, __jsx("div", {
    className: "flex justify-center items-center mt-72 px-10"
  }, __jsx("div", {
    className: "max-w-2xl text-center"
  }, __jsx("div", {
    className: "absolute left-0"
  }, __jsx(Image, {
    src: blockYellow,
    alt: "",
    width: 165,
    height: 344
  })), __jsx("div", {
    className: "absolute right-0 top-48"
  }, __jsx(Image, {
    src: blockBlue,
    alt: "",
    width: 170,
    height: 349
  })), __jsx(Heading, null, "Page Not Found"), __jsx("div", {
    className: "mt-5"
  }, __jsx(Paragraph, null, "Sorry, we were not able to find this page :(")), __jsx("div", {
    className: "mt-10"
  }, __jsx(Button, {
    size: "lg",
    color: "gradient-v2",
    asLink: true,
    href: "/"
  }, "Back to home"))))));
};

export default Custom404;