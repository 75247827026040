import classNames from 'classnames';
import React, { ReactElement, useEffect } from 'react';
import { SideMenu, COLLECTION_BASE_URL, WALLET_BASE_URL } from './SideMenu';
import { TopBarSearch } from '@components/TopBarSearch';
import { useWeb3Context } from '@utils/Web3Context';

interface LayoutProps {
  fullWidth?: boolean;
  customButtons?: ReactElement;
  title?: string | ReactElement;
}

export const Layout: React.FC<LayoutProps> = props => {
  const { checkIfWalletConnected } = useWeb3Context();

  useEffect(() => {
    checkIfWalletConnected();
  }, []);

  return (
    <div className="w-full min-h-screen bg-background-light-mode dark:bg-background-dark-mode">
      <div className=" text-black  dark:text-white flex w-full">
        <SideMenu />
        <div className="w-full relative overflow-auto">
          <div className="bg-background-light-mode text-black dark:bg-background-dark-mode dark:text-white w-full">
            <div className="w-full  border-black dark:border-white border-opacity-15 dark:border-opacity-15">
              <div
                className={classNames(
                  'w-full flex flex-col items-start justify-start lg:flex-row lg:items-center sm:justify-between py-2 ',
                  {
                    'mx-auto max-w-screen-2xl ': !props.fullWidth,
                    'px-4 sm:px-8 lg:px-10 ': props.fullWidth,
                  }
                )}
              >
                <div
                  className={classNames(
                    'w-full flex flex-col items-start justify-start lg:flex-row lg:items-center sm:justify-between ',
                    {
                      'px-4 sm:px-8 lg:px-10 mx-auto max-w-screen-2xl': !props.fullWidth,
                    }
                  )}
                >
                  <div className="flex items-center justify-start basis-full pt-2 ">
                    {typeof props.title === 'string' ? (
                      <div className={'font-grifter text-3xl font-bold capitalize'}>
                        {props.title}
                      </div>
                    ) : (
                      props.title
                    )}
                  </div>
                  <div className="w-full flex items-center justify-end">
                    <div className="w-full lg:w-96">
                      <TopBarSearch
                        autoFocus={false}
                        fullWidth
                        placeholder={'Search for collection or wallet'}
                        collectionRedirectBaseUrl={COLLECTION_BASE_URL}
                        walletRedirectBaseUrl={WALLET_BASE_URL}
                      />
                    </div>
                    <div>{props.customButtons}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames('w-full overflow-y-auto overflow-x-hidden', {
                'px-4 sm:px-8 lg:px-10 pb-6 mx-auto max-w-screen-2xl': !props.fullWidth,
                'px-1 sm:px-6 ': props.fullWidth,
              })}
            >
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
